import Translate from 'src/components/translate';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useCurrentUser from 'src/hooks/use-current-user';
import { useShowNotification } from 'src/hooks/use-notification';
import useSdk, { ValidationError } from 'src/hooks/use-sdk';
import { useTrackingParameters } from 'src/hooks/use-tracking-parameters';
import useTranslate from 'src/hooks/use-translate';
import { useAuth } from 'src/state/auth';
import { trackEvent } from 'src/tracking';
import { useTrackingContext } from 'src/tracking/tracking-context';
import { getClientDeviceId } from 'src/utilities/device-info';

export type SignupInput = {
  username: string;
  password: string;
  affiliateGroup: string;
  deviceInfo: string;
};

type Validation = 'format' | 'unique' | 'length';

export class SignupError extends Error {
  field: keyof SignupInput;
  validation: Validation;
  constructor(field: keyof SignupInput, validation: Validation) {
    super(`${field}_${validation}`.toUpperCase());
    this.name = 'SignupError';
    this.field = field;
    this.validation = validation;
  }
}

export function useSignup({ successNotification = true }: { successNotification?: boolean }) {
  const t = useTranslate();
  const sdk = useSdk();
  const currentLocale = useCurrentLocale();
  const showNotification = useShowNotification();
  const { storeTokens } = useAuth();
  const trackingDisplayContext = useTrackingContext();
  const { refreshCurrentUser } = useCurrentUser();
  const { fbc, fbp } = useTrackingParameters();

  const signup = async ({ username, password, affiliateGroup, deviceInfo }: SignupInput): Promise<void> => {
    try {
      const response = await sdk.registerUser({
        input: {
          academicTitle: undefined,
          affiliateGroup,
          agreeToTos: true,
          clientDeviceId: getClientDeviceId(),
          country: undefined,
          deviceInfo,
          email: username,
          firstName: undefined,
          lastName: undefined,
          locale: currentLocale,
          password,
          sendNewsletter: undefined,
          title: undefined,
          metaFbc: fbc,
          metaFbp: fbp,
        },
      });
      const result = response?.registerUser?.result;
      const errors = response?.registerUser?.errors;
      const validationErrors = errors?.filter((error): error is ValidationError => 'field' in error);

      // Store tokens
      if (result) {
        const { accessToken, refreshToken } = result;
        // store tokens in cookie/local storage
        storeTokens({ accessToken, refreshToken });

        // display a notification with success message including the email address
        if (successNotification) {
          showNotification({
            title: t('notification__signed_up_title'),
            text: (
              <Translate
                i18nKey="notification__signed_up_text"
                components={{
                  email: <strong className="text-info">{username}</strong>,
                }}
              />
            ),
            type: 'success',
            hideAfterSeconds: 15,
          });
        }
        // track sign-up success
        trackEvent({ eventName: 'SignupSuccess', method: 'Email', displayContext: trackingDisplayContext });
        // fetch the new user data so the UI can be updated based on that
        await refreshCurrentUser();
      }

      if (validationErrors?.length) {
        for (const error of validationErrors) {
          if (error.validation !== 'unique' && error.validation !== 'format' && error.validation !== 'length') continue;
          if (error.field === 'email') {
            throw new SignupError('username', error.validation);
          }
          if (error.field === 'password') {
            throw new SignupError('password', error.validation);
          }
        }
      }
    } catch (error) {
      // Track both general Errors and SignupErrors
      if (error instanceof Error) {
        trackEvent({
          eventName: 'SignupError',
          method: 'Email',
          message: error.message,
          displayContext: trackingDisplayContext,
        });
      }

      if (error instanceof SignupError) {
        // Throw the SignupError to be handled by the caller
        throw error;
      } else if (error instanceof Error) {
        // Show a notification for all other errors
        showNotification({
          title: t('notification__error_sign_up_title'),
          text: error.message,
          type: 'error',
          hideAfterSeconds: 15,
        });
      }
    }
  };

  return {
    signup,
  };
}
